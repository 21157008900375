<template>
  <c-flex flex-dir="column">
    <c-text font-size="3xl">Edit Klien</c-text>
    <ClientForms :is-edit="true" v-model="client" v-chakra mt="30px" />
    <c-stack mt="30px" is-inline spacing="20px">
      <c-button
        type="button"
        variant-color="gray"
        color="brand.900"
        rounded="full"
        h="62px"
        w="290px"
        as="router-link"
        :to="{ name: 'superadmin.clients-detail', params: $route.params }"
      >
        Batal
      </c-button>
      <c-button
        type="button"
        rounded="full"
        variant-color="brand"
        h="62px"
        w="290px"
        @click.prevent="submit_()"
        :is-disabled="isSubmitting"
      >
        Simpan
      </c-button>
    </c-stack>
  </c-flex>
</template>

<script>
import ClientForms from "./forms.vue";

export default {
  name: "SUClientEdit",
  components: { ClientForms },
  data() {
    return {
      client: null,
      isSubmitting: false,
    };
  },
  computed: {
    requiredFields() {
      return [];
    },
    clientId() {
      return this.$route.params.clientId;
    },
    client_() {
      return this.$store.getters["suManagementClient/clientById"](
        this.clientId
      );
    },
  },
  watch: {
    client_: {
      immediate: true,
      handler(val) {
        this.client = val;
      },
    },
  },
  mounted() {
    this.$store.dispatch("suManagementClient/getClientById", this.clientId);
  },
  methods: {
    async submit_() {
      this.isSubmitting = true;

      if (this.client.photoFile != null) {
        let form = new FormData();
        form.set("file", this.client.photoFile);
        let photoUrl = await this.axios
          .post(`/v1/users/upload`, form)
          .then((r) => r.data.data.url);
        this.client.photoUrl = photoUrl;
      }

      this.client.id ??= this.clientId;
      this.client.status ??= "verified";
      for (let key of Object.keys(this.client)) {
        if (this.client[key] == null) {
          delete this.client[key];
        }
      }

      await this.$store
        .dispatch("suManagementClient/updateClient", this.client)
        .then(() =>
          this.$router.replace({
            name: "superadmin.clients-detail",
            params: { clientId: this.clientId },
          })
        )
        .catch((err) => {
          let data = err.response.data;
          this.$toast({
            title: "Failed",
            description: data.message,
            status: "error",
            position: "bottom-right",
          });
          this.isSubmitting = false;
        });
    },
  },
};
</script>

<style scoped></style>
